/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'
import { formStatus, getType } from '@/utils/options';

export var columns = function columns(ctx) {return [
  // {
  //   label: 'ID',
  //   type: 'index',
  //   width: 100
  // },
  {
    label: '积分变更时间',
    kname: 'title' },

  {
    label: '变更原因',
    kname: 'source' },

  {
    label: '积分',
    kname: 'createTime' }];};




export var searchOption = [
{
  kname: 'year',
  label: '积分变更时间：',
  component: {
    name: 'c-date-picker',
    props: {
      clearable: true,
      type: 'daterange',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd' } } }];